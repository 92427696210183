// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormCard_formCard__ja3Vo {\n    min-width: 400px !important;\n    width: auto;\n    padding: 3rem;\n}\n.FormCard_disabledbutton__ADmdi {\n    pointer-events: none;\n    opacity: 0.4;\n}\n@media screen and (max-width: 636px) {\n    .FormCard_formCard__ja3Vo {\n        padding: 1rem;\n        min-width: 300px !important;\n    }\n}\n\n", "",{"version":3,"sources":["webpack://./src/styles/FormCard.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,WAAW;IACX,aAAa;AACjB;AACA;IACI,oBAAoB;IACpB,YAAY;AAChB;AACA;IACI;QACI,aAAa;QACb,2BAA2B;IAC/B;AACJ","sourcesContent":[".formCard {\n    min-width: 400px !important;\n    width: auto;\n    padding: 3rem;\n}\n.disabledbutton {\n    pointer-events: none;\n    opacity: 0.4;\n}\n@media screen and (max-width: 636px) {\n    .formCard {\n        padding: 1rem;\n        min-width: 300px !important;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formCard": "FormCard_formCard__ja3Vo",
	"disabledbutton": "FormCard_disabledbutton__ADmdi"
};
export default ___CSS_LOADER_EXPORT___;
