// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddInventory_borderedHr__F80Te {\n    border-top: 2px dashed #DEDFE4;\n    height: 0 !important;\n    background: none !important;\n}\n.AddInventory_borderedHr2__Wx\\+Pu {\n    border-top: 2px dashed #c5c7d1;\n    height: 0 !important;\n    background: none !important;\n}\n\n.AddInventory_formMain__Oyj\\+0 {\n    padding: 0 3rem !important;\n}\n\n@media screen and (max-width: 768px) {\n    .AddInventory_formMain__Oyj\\+0 {\n        padding: 0 1rem !important;\n    }\n}", "",{"version":3,"sources":["webpack://./src/styles/AddInventory.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,oBAAoB;IACpB,2BAA2B;AAC/B;AACA;IACI,8BAA8B;IAC9B,oBAAoB;IACpB,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".borderedHr {\n    border-top: 2px dashed #DEDFE4;\n    height: 0 !important;\n    background: none !important;\n}\n.borderedHr2 {\n    border-top: 2px dashed #c5c7d1;\n    height: 0 !important;\n    background: none !important;\n}\n\n.formMain {\n    padding: 0 3rem !important;\n}\n\n@media screen and (max-width: 768px) {\n    .formMain {\n        padding: 0 1rem !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderedHr": "AddInventory_borderedHr__F80Te",
	"borderedHr2": "AddInventory_borderedHr2__Wx+Pu",
	"formMain": "AddInventory_formMain__Oyj+0"
};
export default ___CSS_LOADER_EXPORT___;
