// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddForm_background__amtq7 {\n    opacity: 0.1;\n    filter: blur(10px);\n}\n\n.AddForm_formContent__9FwI- {\n    z-index: 1;\n}\n\n.AddForm_addForm__DcEla {\n    padding: 0 50px !important;\n}\n.AddForm_form__510wo {\n    width: 70% !important;\n}\n\n@media screen and (max-width: 768px) {\n    .AddForm_addForm__DcEla {\n        padding: 0 10px !important;\n    }\n    .AddForm_form__510wo {\n        width: 100% !important;\n    }\n }\n\n @media screen and (max-width: 1024px) {\n    .AddForm_addForm__DcEla {\n        padding: 0 10px !important;\n    }\n    .AddForm_form__510wo {\n        width: 100% !important;\n    }\n }", "",{"version":3,"sources":["webpack://./src/styles/AddForm.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI;QACI,0BAA0B;IAC9B;IACA;QACI,sBAAsB;IAC1B;CACH;;CAEA;IACG;QACI,0BAA0B;IAC9B;IACA;QACI,sBAAsB;IAC1B;CACH","sourcesContent":[".background {\n    opacity: 0.1;\n    filter: blur(10px);\n}\n\n.formContent {\n    z-index: 1;\n}\n\n.addForm {\n    padding: 0 50px !important;\n}\n.form {\n    width: 70% !important;\n}\n\n@media screen and (max-width: 768px) {\n    .addForm {\n        padding: 0 10px !important;\n    }\n    .form {\n        width: 100% !important;\n    }\n }\n\n @media screen and (max-width: 1024px) {\n    .addForm {\n        padding: 0 10px !important;\n    }\n    .form {\n        width: 100% !important;\n    }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "AddForm_background__amtq7",
	"formContent": "AddForm_formContent__9FwI-",
	"addForm": "AddForm_addForm__DcEla",
	"form": "AddForm_form__510wo"
};
export default ___CSS_LOADER_EXPORT___;
