// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Subscription_Trial_span__OWYYR{\n    margin-top: -29px;\n    margin-right: -37px;\n    background: #F5F6FA;\n    padding: 2px;\n    padding-left: 6px;\n    padding-right: 6px;\n}\n.Subscription_Trial_div__1H9LQ{\n    border: 1px solid #6610f2;\n    border-radius: 7px;\n}\n.Subscription_Active_span__2EEq2{\n    margin-top: -29px;\n    margin-right: -50px;\n    background: #F5F6FA;\n    padding: 2px;\n    padding-left: 6px;\n    padding-right: 6px;\n}\n.Subscription_Cancel_span__ow0Vk{\n    margin-top: -29px;\n    margin-right: -53px;\n    background: #F5F6FA;\n    padding: 2px;\n    padding-left: 6px;\n    padding-right: 6px;\n}\n.Subscription_cancel_div__Wwv93{\n    border: 1px solid #6610f2;\n    border-radius: 7px;\n    width: 80px;\n}\n\n", "",{"version":3,"sources":["webpack://./src/styles/Subscription.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".Trial_span{\n    margin-top: -29px;\n    margin-right: -37px;\n    background: #F5F6FA;\n    padding: 2px;\n    padding-left: 6px;\n    padding-right: 6px;\n}\n.Trial_div{\n    border: 1px solid #6610f2;\n    border-radius: 7px;\n}\n.Active_span{\n    margin-top: -29px;\n    margin-right: -50px;\n    background: #F5F6FA;\n    padding: 2px;\n    padding-left: 6px;\n    padding-right: 6px;\n}\n.Cancel_span{\n    margin-top: -29px;\n    margin-right: -53px;\n    background: #F5F6FA;\n    padding: 2px;\n    padding-left: 6px;\n    padding-right: 6px;\n}\n.cancel_div{\n    border: 1px solid #6610f2;\n    border-radius: 7px;\n    width: 80px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Trial_span": "Subscription_Trial_span__OWYYR",
	"Trial_div": "Subscription_Trial_div__1H9LQ",
	"Active_span": "Subscription_Active_span__2EEq2",
	"Cancel_span": "Subscription_Cancel_span__ow0Vk",
	"cancel_div": "Subscription_cancel_div__Wwv93"
};
export default ___CSS_LOADER_EXPORT___;
