// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_successBg__0IYdX {\n    background-color: #C0F1D1;\n    border-radius: 6px;\n}\n\n.Modal_errorBg__E78iq {\n    background-color: #FCD4CC;\n    border-radius: 6px;\n}\n\n.Modal_modalIcon__PgqHG {\n    font-size: 3rem;\n}\n\n.Modal_successIcon__4rUBg {\n    color: #2DD267;\n}\n\n.Modal_errorIcon__SAP3p {\n    color: #F85E40;\n}", "",{"version":3,"sources":["webpack://./src/styles/Modal.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".successBg {\n    background-color: #C0F1D1;\n    border-radius: 6px;\n}\n\n.errorBg {\n    background-color: #FCD4CC;\n    border-radius: 6px;\n}\n\n.modalIcon {\n    font-size: 3rem;\n}\n\n.successIcon {\n    color: #2DD267;\n}\n\n.errorIcon {\n    color: #F85E40;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successBg": "Modal_successBg__0IYdX",
	"errorBg": "Modal_errorBg__E78iq",
	"modalIcon": "Modal_modalIcon__PgqHG",
	"successIcon": "Modal_successIcon__4rUBg",
	"errorIcon": "Modal_errorIcon__SAP3p"
};
export default ___CSS_LOADER_EXPORT___;
